<template>
  <div>
    <v-container>
      <h2 class="text-h4 mb-4">Feeds</h2>
      <v-card class="mb-4" style="padding: 10px">
        <v-col cols="12" md="3">
          <v-text-field
            v-model="search_keyword"
            label="Search Keyword"
            outlined
            dense
            placeholder="Search Keyword"
            hide-details
          ></v-text-field>
        </v-col>
      </v-card>
      <v-data-table :search="search_keyword" :headers="headers" :items="feeds" :items-per-page="5" class="elevation-1">
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.title }}</td>
              <td>{{ item.product_count }}</td>
              <td>
                <v-chip small class="ma-2" color="green" text-color="white" v-if="item.submit_status == '1'"
                  >Submitted</v-chip
                >
                <v-chip small class="ma-2" color="red" text-color="white" v-else>Not Submitted</v-chip>
              </td>
              <td>
                {{ item.created | date_format('timeago') }}
              </td>
              <td>
                <router-link :to="{ name: 'feed-details', params: { feed_id: item.id } }">
                  <v-btn fab small color="primary darken-1" class="table-action--button">
                    <v-icon small dark>{{ icons.mdiMagnify }}</v-icon>
                  </v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="6" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import store from '@/store'
import { mdiMagnify } from '@mdi/js'
export default {
  name: 'Feeds',
  data() {
    return {
      search_keyword: null,
      loading_data: false,
      feeds: [],
      headers: [
        {
          text: 'Feed Name',
          value: 'title',
        },
        { text: 'Total Products', value: 'name' },
        { text: 'Status', value: 'title' },
        { text: 'Created', value: 'title' },
        { text: 'Actions', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
      },
    }
  },
  mounted() {
    this.shopdetail = store.getters.loggedInShop
    this.fetchShopFeeds()
  },
  methods: {
    async fetchShopFeeds() {
      try {
        try {
          this.loading_data = true
          let response = await axios.get(`admin/shops/${this.shopdetail.id}/get-feeds`)
          if (response.data.success) {
            this.feeds = response.data.feeds
          }
        } catch (e) {
        } finally {
          this.loading_data = false
        }
      } catch {
      } finally {
      }
    },
  },
}
</script>